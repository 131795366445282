import React from "react"
// import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"
import Flex from "../atoms/Flex"
import Button from "../atoms/Button"
import Icon from "../atoms/Icon"
import SectionTitle from "../molecules/SectionTitle"

export const renderIcon = ({ icon, iconStyles }) => {
  if (!icon) return null

  const iconSize = iconStyles?.iconSize

  return (
    icon && (
      <Icon
        flexShrink={0}
        {...icon}
        mr={5}
        width={iconSize ? `${iconSize}px` : 2}
        height={iconSize ? `${iconSize}px` : 2}
      />
    )
  )
}

const ThinCta = ({
  section,
  sectionTitle,
  TCTASectionTitle,
  button,
  TCTAButton,
  icon,
  TCTAIcon,
  iconStyles,
}) => {
  sectionTitle = TCTASectionTitle || sectionTitle
  button = TCTAButton || button
  icon = TCTAIcon || icon
  return (
    <Section {...section} verticalPadding={"custom"} customVerticalPadding={30}>
      <Reveal cascade>
        <Flex
          flexDirection={{ _: "column", md: "row" }}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Flex
            alignItems={"center"}
            alignSelf={"flex-start"}
            mb={{ _: 5, md: 0 }}
          >
            {renderIcon({ icon, iconStyles })}

            {sectionTitle && <SectionTitle {...sectionTitle} maxWidth={42} />}
          </Flex>
          {button && (
            <Button
              aria-label="CTA button in a section of a page"
              {...button}
              ml={{ _: 0, md: 5 }}
            />
          )}
        </Flex>
      </Reveal>
    </Section>
  )
}

export default ThinCta

export const query = graphql`
  fragment ThinCta on STRAPI__COMPONENT_SECTIONS_THIN_CTA {
    strapi_id
    THINCTASection: section {
      ...Section
    }
    TCTASectionTitle: sectionTitle {
      ...SectionTitle
    }
    TCTAButton: button {
      ...Button
    }
    TCTAIcon: icon {
      ...Icon
    }
    iconStyles {
      ...IconStyles
    }
  }
`
