import React, { useMemo } from "react"

import Box from "../../atoms/Box"
import Button from "../../atoms/Button"
import Card from "../../atoms/Card"
import Flex from "../../atoms/Flex"
import SocialLink from "../../utils/SocialLink"

import SectionTitle from "../../molecules/SectionTitle"
import Newsletter from "../../molecules/Newsletter"
import Icon from "../../atoms/Icon"
// destructured variables in the function parameters below should come from props passed as an argument

export const getMediaPositioning = ({ mediaPositioning }) => {
  const value = mediaPositioning === null ? "floating" : mediaPositioning
  return value
}

export const getAlignProp = (
  { alignProp, imageTextAlign, overlap },
  layoutType,
  mediaPositioning
) => {
  const result =
    overlap && layoutType === "stacked"
      ? "end"
      : overlap && layoutType === "adjacent" && mediaPositioning !== "floating"
      ? "center"
      : overlap && layoutType === "adjacent"
      ? "start"
      : imageTextAlign || alignProp //setting align setting depending on layout when overlap is turned on

  return result
}

export const getSectionVerticalPadding = ({ section }) => {
  if (!section) return null

  return section?.verticalPadding === null
    ? "default"
    : section?.verticalPadding === "fullHeight"
    ? "custom"
    : section?.verticalPadding
}

export const isMediaPositioningFloating = ({ mediaPositioning }) => {
  let result = false

  if (mediaPositioning) result = mediaPositioning === "floating"

  return result
}

export const getMediaStyles = isMediaPositioningFloating => {
  return isMediaPositioningFloating
    ? {}
    : {
        width: "100%",
        height: "100%",
      }
}

export const getDefaultPadding = ({ section }) => {
  if (!section || !section.verticalPadding || isNaN(section?.verticalPadding))
    return null

  return section?.customVerticalPadding > 0 &&
    Math.floor(section?.customVerticalPadding / 32) >= 2
    ? section?.customVerticalPadding - 16
    : 32
}

export const getCustomPadding = ({ section }, defaultPadding) => {
  return {
    _: section?.verticalPadding === "custom" ? `${defaultPadding}px` : 7,
    lg: section?.customVerticalPadding
      ? `${section?.customVerticalPadding}px`
      : null,
  }
}

export const getConditionalProps = (
  { section },
  isMediaPositioningFloating,
  customPadding,
  overlap
) => {
  if (
    !section ||
    !section.verticalPadding ||
    isNaN(section?.verticalPadding) ||
    !customPadding
  )
    return null

  return {
    py: {
      _: !isMediaPositioningFloating && !overlap ? 7 : 0,
      // prevent unwanted spacing between image and text on mobile pb
      lg: !overlap
        ? !isMediaPositioningFloating && section.verticalPadding === "custom"
          ? customPadding.lg
          : !isMediaPositioningFloating && section.verticalPadding === "md"
          ? 9
          : (!isMediaPositioningFloating && section.verticalPadding === "lg") ||
            (!isMediaPositioningFloating &&
              section.verticalPadding === "fullHeight")
          ? 12
          : null
        : overlap
        ? !isMediaPositioningFloating
          ? 0
          : null
        : null,
    },
  }
}

export const isOverlapping = ({ layoutStyle }) => {
  return layoutStyle === "overlapping"
}

export const renderTextChild = (
  { text, card, isMediaPositioningFloating, overlap },
  {
    layoutType,
    renderButtons,
    renderNewsletter,
    renderSocials,
    renderIcon,
    mediaRatio,
    hasImageOrVideo,
    mediaPositioning,
  }
) => {
  if (!text) return null

  return (
    <Card
      className="card"
      pt={{ _: card?.useCard ? 5 : 2, md: card?.useCard ? 6 : 5 }}
      pb={{ _: card?.useCard ? 5 : 2, md: card?.useCard ? 6 : 5 }}
      px={{ _: card?.useCard ? 5 : 0, md: card?.useCard ? 6 : 0 }}
      width={
        hasImageOrVideo && overlap && layoutType === "adjacent"
          ? overlap && mediaPositioning === "floating"
            ? "100%"
            : !isMediaPositioningFloating
            ? { _: "100%", md: `${135 + mediaRatio}%` }
            : { _: "100%", md: `${210 + mediaRatio}%` }
          : null
      }
      overflow={card?.useCard ? "auto" : "visible"} //allowing card content to overflow when card is off to avoid unexpected text cutting
      {...card}
    >
      <Box
        width={!isMediaPositioningFloating ? "100%" : "inherit"}
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems={text.align === "left" ? "start" : "center"}
        maxWidth={
          layoutType === "stacked" && text?.titleMaxWidth === "fullWidth"
            ? "100%"
            : hasImageOrVideo &&
              layoutType === "stacked" &&
              text?.titleMaxWidth === "wide"
            ? { _: "100%", md: 45, lg: 56 }
            : null
        }
      >
        {renderIcon}
        <SectionTitle {...text} />
        {renderButtons}
        {renderNewsletter}
        {renderSocials}
      </Box>
    </Card>
  )
}

export const hasImageOrVideo = props => {
  return (
    (props.imageOrVideo?.isVideoSelected &&
      (props.imageOrVideo?.videoURL ||
        props.imageOrVideo?.videoImageFallback)) ||
    props.imageOrVideo?.desktopImage ||
    props.imageOrVideo?.mobileImage
  )
}

export const getMediaRatio = ({ mediaRatio }) => {
  return mediaRatio === null ? 50 : mediaRatio
}

export const getLocation = ({ imageTextLocation, location }) => {
  return imageTextLocation || location
}

export const getLayoutType = location => {
  if (location === "right" || location === "left") return "adjacent"

  if (location === "bottom" || location === "top") return "stacked"
}

export const renderNewsletter = ({ useNewsletter, newsletter }) => {
  if (!useNewsletter) return null

  return (
    <Box mt={5}>
      <Newsletter justify={"start"} {...newsletter} />
    </Box>
  )
}
export const renderIcon = ({ iconAttribute, iconStyles }) => {
  iconAttribute = iconAttribute?.image
  if (!iconAttribute) return null

  const iconSize = iconStyles?.iconSize

  return (
    iconAttribute && (
      <Icon
        flexShrink={0}
        {...iconAttribute}
        mb={4}
        width={iconSize ? `${iconSize}px` : 2}
        height={iconSize ? `${iconSize}px` : 2}
      />
    )
  )
}

export const renderButtons = ({
  button,
  button2,
  openModalSectionById,
  modal,
  text,
}) => {
  if (!button && !button2) return null

  if (button && button2 && modal?.size?.sizeSelections === "sm") {
    return (
      <Flex
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="flex-end"
        style={{ width: "70%" }}
      >
        <Button
          aria-label="CTA button 1 in a section of a page"
          mt={5}
          {...button}
        />
        <Button
          aria-label="CTA button 2 in a section of a page"
          mt={5}
          {...button2}
        />
      </Flex>
    )
  }

  const buttons = []
  if (button) {
    buttons.push(
      <Button
        aria-label="CTA button in a section of a page 1"
        mt={5}
        mr={{ _: 0, md: button2 ? 5 : 0 }}
        {...button}
      />
    )
  }

  if (button2) {
    buttons.push(
      <Button
        aria-label="CTA button in a section of a page 2"
        mt={5}
        {...button2}
      />
    )
  }

  return (
    <Flex
      flexDirection={{ _: "column", md: "row" }}
      justifyContent={text?.align === "center" ? "center" : "flex-start"}
      alignItems={{
        _: text?.align === "center" ? "center" : "flex-start",
        lg: "center",
      }}
    >
      {buttons}
    </Flex>
  )
}

export const renderSocials = ({ socialLinks, socialLinkStyles, text }) => {
  if (socialLinks?.length === 0 || socialLinks === undefined) return null

  const socialStyles =
    socialLinkStyles === undefined || socialLinkStyles === null
      ? {
          color: "primary",
          theme: "highlight",
          bg: 3,
          iconStyle: "solid",
        }
      : socialLinkStyles

  return (
    <Box className="social" mt={5} textAlign={text?.align}>
      {socialLinks?.map(socialLink => {
        return <SocialLink {...socialLink} {...socialStyles} mr={5} />
      })}
    </Box>
  )
}

// left commented out code to highlight which functions need normalizedProps once we use the image text molecule again
export const useMemoizedProps = props => {
  // const normalizedProps = props.imageTextMolecule
  //   ? props.imageTextMolecule
  //   : props

  const storage = {
    isMediaPositioningFloating: isMediaPositioningFloating(props),
    // isMediaPositioningFloating: useMemo(
    //   () => isMediaPositioningFloating(normalizedProps),
    //   [props.imageTextMolecule.mediaPositioning]
    // ),
    mediaPositioning: getMediaPositioning(props),
    // mediaPositioning: useMemo(() => getMediaPositioning(normalizedProps), [
    //   props.imageTextMolecule.mediaPositioning,
    // ]),
    conditionalProps: getConditionalProps(
      props,
      isMediaPositioningFloating(props),
      getCustomPadding(props, getDefaultPadding(props)),
      isOverlapping(props)
    ),
    verticalPadding: getSectionVerticalPadding(props),
    overlap: isOverlapping(props),
    customPadding: getCustomPadding(props, getDefaultPadding(props)),
    textChild: renderTextChild(props, {
      layoutType: getLayoutType(getLocation(props)),
      renderButtons: renderButtons(props),
      renderNewsletter: renderNewsletter(props),
      renderIcon: renderIcon(props),
      renderSocials: renderSocials(props),
      mediaRatio: getMediaRatio(props),
      hasImageOrVideo: hasImageOrVideo(props),
      mediaPositioning: getMediaPositioning(props),
    }),
  }

  return Object.keys(storage).reduce((result, field) => {
    result[field] = storage[field]
    return result
  }, {})
}
