import React from "react"
import { Title, Paragraph } from "../atoms/Typography"

import PropTypes from "prop-types"
import { notGatsby, graphql } from "gatsby"

import Section from "../atoms/Section"
import Card from "../atoms/Card"
import LayoutChanger from "../atoms/LayoutChanger"
import Box from "../atoms/Box"
import Image from "../utils/Image"

import SectionTitle from "../molecules/SectionTitle"
import FormIntegration from "../integrations/FormIntegration"
import Icon from "../atoms/Icon"
import Flex from "../atoms/Flex"

// Backup if site was created before card option
const defaultCard = {
  borderRadius: "lg",
  boxShadow: "lg",
  bg: 0,
  theme: "default",
  useCard: true,
}

const defaultCaptcha = {
  useCaptcha: false, // if no captcha is pass, assumed it is turned off
  size: "normal", //normal, invisible or compact
  theme: "dark", //light, dark
  // badge =bottomright {bottomright, bottomleft or inline}. Positions reCAPTCHA badge. Only for invisible reCAPTCHA
}

export const renderIcon = ({ iconAttribute, iconStyles }) => {
  iconAttribute = iconAttribute?.image
  if (!iconAttribute) return null

  const iconSize = iconStyles?.iconSize

  return (
    iconAttribute && (
      <Icon
        flexShrink={0}
        {...iconAttribute}
        mb={4}
        width={iconSize ? `${iconSize}px` : 2}
        height={iconSize ? `${iconSize}px` : 2}
      />
    )
  )
}

const ContactForm = ({
  section,
  sectionTitle,
  card = defaultCard,
  integration,
  layoutChanger,
  image,
  captcha,
  iconAttribute,
  iconStyles,
  successURLRedirect,
}) => {
  captcha = captcha === null ? defaultCaptcha : captcha

  return (
    <Section {...section}>
      <LayoutChanger
        itemsMoveStackedOnColumn={true}
        child1Style={
          integration &&
          integration.type === "form" && {
            justifyContent:
              sectionTitle.align === "center" ? "center" : "flex-start",
          }
        }
        {...layoutChanger}
      >
        <Box maxWidth={44} width="100%">
          <Flex
            flexDirection="column"
            alignItems={sectionTitle?.align === "left" ? "start" : "center"}
          >
            {renderIcon({ iconAttribute, iconStyles })}
            {sectionTitle && <SectionTitle {...sectionTitle} />}
          </Flex>
          {image && (
            <Image alternativeText="Image In A Contact Form" {...image} />
          )}
        </Box>
        {integration && integration.type === "form" ? (
          <Card
            {...card}
            padding={card.useCard ? { _: 5, md: 6 } : 0}
            maxWidth={44}
            width="100%"
          >
            <FormIntegration
              {...integration}
              captcha={captcha}
              successURLRedirect={successURLRedirect}
            />
          </Card>
        ) : (
          !integration &&
          notGatsby && (
            <Flex
              width="100%"
              height="100%"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              py="85px"
              boxShadow="0px 15px 35px 0px rgba(0,0,0, 15%)"
            >
              <Title
                style={{
                  fontWeight: 500,
                  fontFamily: "dm sans",
                  fontSize: "15px",
                }}
                mb="10px"
              >
                FORM INTEGRATION
              </Title>
              <Paragraph
                fontWeight={100}
                mb="15px"
                textAlign="center"
                px="65px"
                style={{
                  fontFamily: "dm sans",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                Create a new form in the Integrations tab and then link it here
                by clicking "Choose an integration" in the sidebar of this
                section.
              </Paragraph>
            </Flex>
          )
        )}
      </LayoutChanger>
    </Section>
  )
}

ContactForm.propTypes = {
  strapi_id: PropTypes.number.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps),
  card: PropTypes.shape(Card.strapiProps),
  integration: PropTypes.shape(FormIntegration.strapiProps),
  ...LayoutChanger.strapiProps,
}

export default ContactForm

export const query = graphql`
  fragment ContactForm on STRAPI__COMPONENT_SECTIONS_CONTACT_FORM {
    strapi_id
    CONTACTFORMSection: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    successURLRedirect {
      ...Link
    }
    card {
      ...Card
    }
    captcha {
      ...Captcha
    }
    integration {
      ...FormIntegration
    }
    layoutChanger {
      ...LayoutChanger
    }
    image {
      ...ImageWithConstrainedLayout
    }
    iconAttribute {
      ...IconAttribute
    }
    iconStyles {
      ...IconStyles
    }
  }
`
